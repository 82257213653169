@mixin generate($prefix, $prop1, $prop2: null, $prop3: null, $prop4: null) {
  $sizes: 0px, 4px, 8px, 16px, 24px, 32px, 48px, 64px, auto;

  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);

    @if $size == auto {
      .#{$prefix}-auto {
        #{$prop1}: $size !important;
        @if $prop2 {
          #{$prop2}: $size !important;
        }
        @if $prop3 {
          #{$prop3}: $size !important;
        }
        @if $prop4 {
          #{$prop4}: $size !important;
        }
      }
    } @else {
      .#{$prefix}-#{$i - 1} {
        @if (str-index($prefix, "-n")) {
          #{$prop1}: -$size !important;
          @if $prop2 {
            #{$prop2}: -$size !important;
          }
          @if $prop3 {
            #{$prop3}: -$size !important;
          }
          @if $prop4 {
            #{$prop4}: -$size !important;
          }
        } @else {
          #{$prop1}: $size !important;
          @if $prop2 {
            #{$prop2}: $size !important;
          }
          @if $prop3 {
            #{$prop3}: $size !important;
          }
          @if $prop4 {
            #{$prop4}: $size !important;
          }
        }
      }
    }
  }
}

@include generate(ma, margin);
@include generate(mt, margin-top);
@include generate(mb, margin-bottom);
@include generate(ml, margin-left);
@include generate(mr, margin-right);
@include generate(mx, margin-left, margin-right);
@include generate(my, margin-top, margin-bottom);

@include generate(ma-n, margin);
@include generate(mt-n, margin-top);
@include generate(mb-n, margin-bottom);
@include generate(ml-n, margin-left);
@include generate(mr-n, margin-right);
@include generate(mx-n, margin-left, margin-right);
@include generate(my-n, margin-top, margin-bottom);

@include generate(pa, padding);
@include generate(pt, padding-top);
@include generate(pb, padding-bottom);
@include generate(pl, padding-left);
@include generate(pr, padding-right);
@include generate(px, padding-left, padding-right);
@include generate(py, padding-top, padding-bottom);
