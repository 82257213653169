@import "_reset";
@import "_spacing";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;400;600;700&family=Noto+Sans:wght@300;400;600;700&display=swap");

// Variable
$border: rgba(85, 85, 85, 0.85);

$border-radius-xs: 4px;
$border-radius-s: 8px;
$border-radius-m: 16px;
$border-radius-l: 40px;
$border-radius-xl: 50%;

// Override

:root {
  font-size: 16px;
  overflow-x: hidden;
}

body {
  font-family: "Noto Sans", "Noto Sans Thai", sans-serif;
  background: #000000;
  line-height: 1.5;
  font-weight: 400;
  color: #ffffff;
  background-image: url("../images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  overflow-x: hidden;
  padding: 0 !important;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > .MuiContainer-root {
    flex-grow: 1;
  }
}

img {
  max-width: 100%;
  display: block;
}

.col {
  display: flex;
  flex-direction: column;

  .full {
    flex-grow: 1;
  }
}
.col-12 {
  width: 100%;
}
.col-9 {
  width: 75%;
}
.col-8 {
  width: 66.666%;
}
.col-7 {
  width: 60%;
}
.col-6 {
  width: 50%;
}
.col-5 {
  width: 40%;
}
.col-4 {
  width: 33.333%;
}
.col-3 {
  width: 25%;
}

.bd-t {
  border-top: 1px solid $border;
}
.bd-b {
  border-bottom: 1px solid $border;
}
.bd-l {
  border-left: 1px solid $border;
}
.bd-r {
  border-right: 1px solid $border;
}
.bd-a {
  border: 1px solid $border;
}
.bd-x {
  border-left: 1px solid $border;
  border-right: 1px solid $border;
}
.bd-y {
  border-top: 1px solid $border;
  border-bottom: 1px solid $border;
}

.br-t {
  border-top-left-radius: $border-radius-s !important;
  border-top-right-radius: $border-radius-s !important;
}
.br-b {
  border-bottom-left-radius: $border-radius-s !important;
  border-bottom-right-radius: $border-radius-s !important;
}
.br-l {
  border-top-left-radius: $border-radius-s !important;
  border-bottom-left-radius: $border-radius-s !important;
}
.br-r {
  border-top-right-radius: $border-radius-s !important;
  border-bottom-right-radius: $border-radius-s !important;
}
.br-a {
  border-radius: $border-radius-s !important;
}

.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-inline {
  display: inline !important;
}

.p-relative {
  position: relative !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-space-around {
  justify-content: space-around;
}
.justify-space-between {
  justify-content: space-between;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}
.align-baseline {
  align-items: baseline;
}
.align-stretch {
  align-items: stretch;
}

.flex-grow {
  flex-grow: 1;
}
.flex-shrink {
  flex-shrink: 0 !important;
}
.align-self-start {
  align-self: flex-start;
}
.align-self-center {
  align-self: center;
}
.align-self-end {
  align-self: flex-end;
}

.justify-self-start {
  justify-self: flex-start;
}
.justify-self-center {
  justify-self: center;
}
.justify-self-end {
  justify-self: flex-end;
}

.text-shadow {
  text-shadow: 1px 4px 2px #000000;
}
